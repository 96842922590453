((
    ($) => {

        let _mainMenu = $('.main-menu');
        let _burger = $('.js-burger');
        if (!_mainMenu.length) {
            _burger.hide();
        }
        _burger.click(function () {
            _mainMenu.fadeIn();
            $('body').css('overflow','hidden');
        });
        $('.js-menu-close').click(function () {
            _mainMenu.fadeOut();
            $('body').css('overflow','visible');
        });
        _mainMenu.find('.main-menu-item').click(function (e) {
            let _clicked = $(this);
            let _subMenu = _clicked.closest('li').find('> .sub-menu');
            if (!_subMenu.length) {
                return true;
            }
            e.preventDefault();
            _subMenu.addClass('active');
        });
        $('.js-menu-back').click(function () {
            $(this).closest('.sub-menu').removeClass('active');
        });

    }
)(jQuery));